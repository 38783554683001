import React from "react";
import { useRenderContext, RelatedLinks } from "@wq/react";
import { TabGroup, TabItem, View, ScrollView } from "@wq/material";
import { AutoMap } from "@wq/map";
import { MapProvider, HighlightPopup } from "@wq/map-gl";
import PropertyTable from "../components/PropertyTable";
import { Analyst } from "@wq/analyst";

export default function SiteDetail() {
    const context = useRenderContext();
    return (
        <View
            sx={{
                "& > div, & > div > div, &  > div > div > div": {
                    overflow: "hidden",
                },
                span: {
                    maxHeight: "50px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                },
            }}
            style={{ flex: 1, display: "flex", overflow: "hidden" }}
        >
            <TabGroup sx={{ minHeight: 72 }}>
                <TabItem value="data" icon="analyst" label="Data">
                    <Analyst />
                </TabItem>
                <TabItem value="map" icon="map" label="Location">
                    <MapProvider>
                        <AutoMap mapId="map" context={context}>
                            <HighlightPopup inMap />
                        </AutoMap>
                        <HighlightPopup />
                    </MapProvider>
                </TabItem>
                <TabItem value="info" icon="detail" label="info">
                    <ScrollView>
                        <PropertyTable
                            values={context}
                            form={context.router_info.page_config.form}
                        />
                        <RelatedLinks
                            id={context.id}
                            model={context.router_info.page_config.name}
                        />
                    </ScrollView>
                </TabItem>
            </TabGroup>
        </View>
    );
}
