import React from "react";
import { useNav, useReverse, useRouteInfo } from "@wq/react";

export default function EventEdit() {
    const nav = useNav(),
        reverse = useReverse(),
        { item_id } = useRouteInfo();
    nav(
        item_id
            ? reverse(
                  "report_list:event",
                  { parent_id: item_id },
                  { mode: "edit" },
              )
            : reverse("report_edit:new"),
    );
    return <div>Choose an individual report to edit.</div>;
}
