import React from "react";
import {
    useList,
    useReverse,
    useModel,
    useRouteInfo,
    Message,
} from "@wq/react";
import {
    ScrollView,
    List,
    ListItem,
    ListItemLink,
    Pagination,
    Fab,
    View,
    Chip,
} from "@wq/material";
import { Alert } from "@mui/material";

export default function ReportList() {
    const {
            page_config: { page, can_add },
            list,
            empty,
        } = useList(),
        { params: { mode } = {} } = useRouteInfo(),
        statuses = useModel("reportstatus"),
        isValid = (row) =>
            statuses.some(
                (status) => status.id === row.status_id && status.is_valid,
            ),
        reverse = useReverse();
    return (
        <>
            <ScrollView>
                {mode === "edit" && (
                    <Alert severity="info">
                        Choose an individual report to edit. If unsure, select
                        the most recent valid report.
                    </Alert>
                )}
                <List>
                    {empty ? (
                        <ListItem>
                            <Message id="LIST_IS_EMPTY" />
                        </ListItem>
                    ) : (
                        list.map((row) => (
                            <ListItemLink
                                key={row.id}
                                to={reverse(
                                    isValid(row) && mode === "edit"
                                        ? `${page}_edit`
                                        : `${page}_detail`,
                                    row.id,
                                )}
                                style={{
                                    color: isValid(row) ? "#339933" : "#773333",
                                }}
                                icon={isValid(row) ? "success" : "close"}
                                description={`${row.source_label} by ${row.user_id} on ${row.entered_label}`}
                            >
                                <View style={{ display: "flex" }}>
                                    <span style={{ flex: 1 }}>{row.label}</span>
                                    <View>
                                        <Chip
                                            size="small"
                                            variant="outlined"
                                            label={row.status_id}
                                        />
                                    </View>
                                </View>
                            </ListItemLink>
                        ))
                    )}
                </List>
                <Pagination />
            </ScrollView>
            {can_add !== false && (
                <Fab icon="add" to={reverse(`${page}_edit:new`)} />
            )}
        </>
    );
}
